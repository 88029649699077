import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ControlBar.module.scss'
import { useDetectScroll } from "@smakss/react-scroll-direction";
import { useMatch } from 'react-router-dom';
import { ReactComponent as IconStar } from "../../resources/star.svg";
import Favorites from '../Favorites/Favorites';
import assetTypes from '../StaticData/assetTypes.js';

const ControlBar = (props) => {
    const { currentListName, filterHandler, selectedExchanges, currentFavoritesList, favoriteLists, setCurrentFavoritesList, allowFavorites, renameList, additionalAssetTypes } = props
    const [scrollDir] = useDetectScroll({});
    const showHideClass = scrollDir === "down" || props.showBusca ? styles["control-bar--hidden"]:"";
    const [showFavorites, setShowFavorites] = useState(false)

    return (
    <div className={`${styles["control-bar"]} ${showHideClass}`}>
        <div className={styles["control-bar__button-array"]} bp="flex">
            {
                assetTypes.map(b=><ButtonExchange key={b.label} label={b.label} exchanges={b.exchanges} assetType={b.assetType} handler={filterHandler} selectedExchanges={selectedExchanges} onClick={()=>{setShowFavorites(false)}}  />)
            }
            {
                additionalAssetTypes && additionalAssetTypes.map(b=><ButtonExchange key={b.label} label={b.label} exchanges={b.exchanges} assetType={b.assetType} handler={filterHandler} selectedExchanges={selectedExchanges} onClick={()=>{setShowFavorites(false)}}  />)
            }
            
            {/* <ButtonExchange label="HK" exchanges="HKEX" handler={props.filterHandler} selectedExchanges={props.selectedExchanges} /> */}
            <div bp="fill 3" className={`${styles["control-bar__button"]} ${styles["control-bar__button-wrapped"]}`}>
            {showFavorites && allowFavorites &&
            <Favorites
                favoriteLists={favoriteLists}
                currentLists={[currentFavoritesList]}
                onChangeFavorites={setCurrentFavoritesList}
                setShowFavorites={setShowFavorites}
                renameList={renameList}
                showEditButton={true}
                className={`${styles["control-bar__favorites"]}`} />
            }
            <ButtonExchange label={<><IconStar /> {currentListName} {showFavorites}</>} exchanges="favoritos" handler={filterHandler} selectedExchanges={selectedExchanges} onClick={()=>{setShowFavorites(!showFavorites)}} />
            </div>
        </div>
    </div>
    )
}

const ButtonExchange = (props) => {
    // const pressedClass = JSON.stringify(props.selectedExchanges.sort()) === JSON.stringify(props.exchanges.toUpperCase().split(",").sort()) ? styles["pressed"]:""
    const urlLocation = useMatch('/:list/*');
    const to = `/${urlLocation?.params?.list || "agulhadas-do-didi"}/${props.exchanges}${props.assetType?"/"+props.assetType:""}`
    // console.log(urlLocation.pathname, to)
    const pressedClass = (urlLocation.pathname === to) ? styles["pressed"]:""

    return (
        <NavLink
            bp="fill 3"
            to={to}
            onClick={props.onClick}
            className={`${styles["control-bar__button"]} ${pressedClass} `} >{props.label}
        </NavLink>
    )
}

export default ControlBar