import useSWR from "swr"

const fetcher = (...args) => fetch(...args).then(res => res.json())

export function useAllAssets (exchange, userAssets) {
    let exchangeQuery = "";
    if(exchange) {
      exchangeQuery = "exchange="+exchange
    }

    let userQuery = "";
    if(userAssets === true) {
      userQuery = "&userassets=1"
    }

    const url = `/api/getAllAssets/?${exchangeQuery}${userQuery}`;
    
    const { data, error, isLoading, mutate, isValidating } = useSWR(url, fetcher)
   
    return {
      listData: data,
      isLoading,
      isError: error,
      mutateAssets: mutate,
      isValidating
    }
}